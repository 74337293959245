import {
  BackpackIcon,
  BookmarkFilledIcon,
  IdCardIcon,
  LightningBoltIcon,
  ReaderIcon,
} from "@radix-ui/react-icons";
import SideMenuItem from "~/components/sideMenuItem";

export default function CandidateMenu({ isMobile }: { isMobile: boolean }) {
  const candidatesMenu: {
    to: string;
    name: string;
    icon: any;
    hidden?: boolean;
  }[] = [
    {
      to: "profile",
      name: "Profile",
      icon: <IdCardIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "cv",
      name: "CV",
      icon: <ReaderIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "applied-jobs",
      name: "Applied Jobs",
      icon: <BackpackIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "saved-jobs",
      name: "Saved Jobs",
      icon: <BookmarkFilledIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "job-alert",
      name: "Job Alert",
      icon: <LightningBoltIcon className="h-8" height={25} width={25} />,
      hidden: true,
    },
  ];

  return (
    <div className="flex-1 sticky top-20">
      <aside>
        <nav>
          <ul>
            {candidatesMenu
              .filter((v) => !v.hidden)
              .map((menu) => {
                return (
                  <SideMenuItem
                    key={menu.name}
                    to={isMobile ? `./candidate/${menu.to}` : menu.to}
                    name={menu.name}
                    icon={menu.icon}
                  />
                );
              })}
          </ul>
        </nav>
      </aside>
    </div>
  );
}
