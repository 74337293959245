import {
  CardStackIcon,
  CardStackPlusIcon,
  DashboardIcon,
  FileTextIcon,
  IdCardIcon,
  Pencil2Icon,
  PersonIcon,
  ReaderIcon,
  RocketIcon,
} from "@radix-ui/react-icons";
import { BookMarkedIcon } from "lucide-react";
import SideMenuItem from "~/components/sideMenuItem";
import { TypographyH2 } from "~/components/ui/typography/h2";

export default function RecruiterMenu({ isMobile }: { isMobile: boolean }) {
  const yourPostingsMenu: {
    to: string;
    name: string;
    icon: any;
    hidden?: boolean;
  }[] = [
    {
      to: "about",
      name: "About",
      icon: <IdCardIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "profile",
      name: "Profile Page",
      icon: <ReaderIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "post-job",
      name: "Post a job",
      icon: <RocketIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "manage-your-jobs",
      name: "Manage your jobs",
      icon: <DashboardIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "applications",
      name: "Applications",
      icon: <FileTextIcon className="h-8" height={25} width={25} />,
    },
    {
      to: "subscriptions",
      name: "Subscriptions",
      icon: <CardStackPlusIcon className="h-8" height={25} width={25} />,
      hidden: true,
    },
  ];

  // const cvSearchMenu: { to: string; name: string; icon: any }[] = [
  //   {
  //     to: "search-cv",
  //     name: "Search CVs",
  //     icon: <PersonIcon className="h-8" height={25} width={25} />,
  //   },
  //   {
  //     to: "saved-cv",
  //     name: "Saved CVs",
  //     icon: <BookMarkedIcon className="h-8" height={25} width={25} />,
  //   },
  // ];

  // const paymentDetailsMenu: { to: string; name: string; icon: any }[] = [
  //   {
  //     to: "billing-information",
  //     name: "Billing Information",
  //     icon: <Pencil2Icon className="h-8" height={25} width={25} />,
  //   },
  //   {
  //     to: "payment-methods",
  //     name: "Payment methods",
  //     icon: <CardStackIcon className="h-8" height={25} width={25} />,
  //   },
  //   {
  //     to: "invoices",
  //     name: "Invoices",
  //     icon: <ReaderIcon className="h-8" height={25} width={25} />,
  //   },
  // ];
  return (
    <div className="flex-1 sticky top-20">
      <aside>
        <nav>
          <TypographyH2 className="my-6 text-lg md:text-xl">
            Your postings
          </TypographyH2>
          <ul>
            {yourPostingsMenu
              .filter((v) => !v.hidden)
              .map((menu) => {
                return (
                  <SideMenuItem
                    key={menu.name}
                    to={isMobile ? `./recruiter/${menu.to}` : menu.to}
                    name={menu.name}
                    icon={menu.icon}
                  />
                );
              })}
          </ul>

          {/* <TypographyH2 className="my-6 text-lg md:text-xl">
            CV Search
          </TypographyH2>
          <ul>
            {cvSearchMenu.map((menu) => {
              return (
                <SideMenuItem
                  key={menu.name}
                  to={isMobile ? `./recruiter/${menu.to}` : menu.to}
                  name={menu.name}
                  icon={menu.icon}
                />
              );
            })}
          </ul> */}

          {/* <TypographyH2 className="my-6 text-lg md:text-xl">
            Payment Details
          </TypographyH2>
          <ul>
            {paymentDetailsMenu.map((menu) => {
              return (
                <SideMenuItem
                  key={menu.name}
                  to={isMobile ? `./recruiter/${menu.to}` : menu.to}
                  name={menu.name}
                  icon={menu.icon}
                />
              );
            })}
          </ul> */}
        </nav>
      </aside>
    </div>
  );
}
