import { Badge } from "./ui/badge";
import { Button } from "./ui/button";

export function Footer() {
  return (
    <>
      <footer className="flex-shrink-0 md:p-6 pb-3 pt-3">
        <div className="flex items-center justify-center md:justify-normal border-b-2 bottom-1 w-full">
          <div className="flex justify-center gap-2">
            <h1 className="text-lg text-lime-700 font-bold hidden md:block">
              Schooljobs
            </h1>
            <Badge
              variant="outline"
              className={`border-red-500  w-14 self-end justify-center hidden md:flex`}
            >
              <div
                style={{ width: "0.4rem", height: "0.4rem" }}
                className="bg-red-500 rounded-full mr-1"
              ></div>
              <div className="font-semibold">LIVE</div>
            </Badge>
          </div>

          {/* <div className="ml-8">
            <Button
              className="text-base font-thin text-slate-800"
              variant={"ghost"}
            >
              About Us
            </Button>
            <Button
              className="text-base font-thin text-slate-800"
              variant={"ghost"}
            >
              Frequent queries
            </Button>
            <Button
              className="text-base font-thin text-slate-800 hidden md:inline-block"
              variant={"ghost"}
            >
              Jobs by locations
            </Button>
          </div> */}
        </div>
        <div className="py-3 text-xs text-center md:text-left">
          © 2023 SCHOOLJOBS LIVE ALL RIGHTS RESERVED
        </div>
      </footer>
    </>
  );
}
