import {
  json,
  type LinksFunction,
  type LoaderFunction } from
"@remix-run/cloudflare";
import { cssBundleHref } from "@remix-run/css-bundle";
import NProgress from "nprogress";

import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  useNavigation } from
"@remix-run/react";

import { rootAuthLoader } from "@clerk/remix/ssr.server";

import styles from "./tailwind.css";
import nProgressStyles from "./nprogress.css";
import quillStyles from "react-quill/dist/quill.snow.css";

// Import ClerkApp
import { ClerkApp, V2_ClerkErrorBoundary } from "@clerk/remix";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Toaster } from "./components/ui/toaster";
import { getToast } from "./utils/toast.server";
import { useToast } from "./components/ui/use-toast";
import { cn } from "./lib/utils";
import { useEffect } from "react";
import { combineHeaders } from "./utils/misc";
import { create } from "zustand";
import { getUserType } from "./utils/getUserType";
import type { GetUserFuncType, UserTypes } from "./utils/getUserType";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { withSentry } from "@sentry/remix";

const firebaseConfig = {
  apiKey: "AIzaSyDOsvPGoAX4gFPGOd1xnabX4XTzFxjV8Nw",
  authDomain: "schooljobslive.firebaseapp.com",
  projectId: "schooljobslive",
  storageBucket: "schooljobslive.appspot.com",
  messagingSenderId: "955968216168",
  appId: "1:955968216168:web:256aa69767a589413befdd",
  measurementId: "G-CB5DSW8E9V"
};

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
{ rel: "stylesheet", href: styles },
{ rel: "stylesheet", href: nProgressStyles },
{ rel: "stylesheet", href: quillStyles }];


export const useCurrentUserType = create<{
  userType: UserTypes;
  getUserType: GetUserFuncType;
  setUserType: (userType: UserTypes) => void;
  setGetUserType: (getUserType: GetUserFuncType) => void;
}>((set) => ({
  userType: null,
  getUserType: null,
  setUserType: (userType: UserTypes) => set(() => ({ userType })),
  setGetUserType: (getUserType: GetUserFuncType) =>
  set(() => ({ getUserType }))
}));

export const loader: LoaderFunction = (args) => {
  return rootAuthLoader(
    args,
    async ({ request, context }) => {
      const { sessionId, userId } = request.auth;
      const { toast, headers: toastHeaders } = await getToast(request);
      const getUserFuncType = await getUserType(context, userId);
      return json(
        { sessionId, userId, toast, getUserFuncType },
        {
          headers: combineHeaders(toastHeaders)
        }
      );
    },
    {
      secretKey: (
      args.context.env as {
        CLERK_SECRET_KEY: string;
        CLERK_PUBLISHABLE_KEY: string;
      }).
      CLERK_SECRET_KEY,
      publishableKey: (
      args.context.env as {
        CLERK_SECRET_KEY: string;
        CLERK_PUBLISHABLE_KEY: string;
      }).
      CLERK_PUBLISHABLE_KEY
    }
  );
};
// add n Error Boundary
export const ErrorBoundary = V2_ClerkErrorBoundary();

function App() {
  const matches = useMatches();
  const loaderData = useLoaderData<{
    sessionId: string;
    userId: string;
    toast: any;
    getUserFuncType: GetUserFuncType;
  }>();
  const { toast } = useToast();
  const hideHeader = matches.some((match) => match.handle?.hideHeader);

  const navigation = useNavigation();

  const setUserType = useCurrentUserType((state) => state.setUserType);
  const setGetUserType = useCurrentUserType((state) => state.setGetUserType);

  setUserType(
    loaderData?.getUserFuncType && loaderData.getUserFuncType?.userType
  );
  setGetUserType(loaderData.getUserFuncType);

  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }, []);

  useEffect(() => {
    if (navigation.state === "loading") NProgress.start();
    if (navigation.state === "idle") NProgress.done();
  }, [navigation.state]);

  useEffect(() => {
    if (loaderData.toast) {
      const { title, description, type } = loaderData.toast;
      toast({
        className: cn(
          "top-0 right-0 flex fixed md:max-w-[420px] md:top-4 md:right-4"
        ),
        variant: type === "message" ? "default" : "destructive",
        title,
        description
      });
    }
  }, [loaderData.toast, toast]);

  return (
    <html lang="en" className="h-full min-h-[800px]">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="apple-mobile-web-app-title" content="School Jobs" />
        <meta name="application-name" content="School Jobs" />
        <meta name="msapplication-TileColor" content="#00a300" />
        <meta
          name="msapplication-config"
          content="/favicon/browserconfig.xml" />

        <meta name="theme-color" content="#ffffff" />
        <Meta />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png" />

        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#65a30d" />

        <Links />
      </head>
      <body className="h-full min-h-[800px] flex flex-col">
        <div className="flex-[1_0_auto]">
          {!hideHeader ? <Header /> : null}
          <div className="py-4 lg:py-10 px-4 mx-auto max-w-6xl ">
            <Outlet />
          </div>
        </div>

        {!hideHeader ? <Footer /> : null}

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        <Toaster />
      </body>
    </html>);

}

// Wrap your app in ClerkApp(app)
export default withSentry(ClerkApp(App));