import { SignedIn, SignedOut } from "@clerk/remix";
import { Button } from "./ui/button";
import { NavLink } from "@remix-run/react";
import { CardStackPlusIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";

import { MobileMenu } from "./mobileMenu";
import { useEffect, useState } from "react";
import { Badge } from "./ui/badge";
import { ClientOnly } from "remix-utils";
import Novu from "./novu.client";
import { Spinner } from "./spinner";

export function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header
        className={`w-full flex py-2 px-4 pl-4 justify-between items-center lg:px-6 sticky bg-white top-0 z-40 ${
          scrollPosition >= 35 ? "border-b border-gray-500/30" : ""
        }`}
      >
        <div className="flex w-2/3 items-center">
          <NavLink to={"."}>
            <div className="flex flex-col">
              <h1 className="text-3xl text-lime-700 font-bold">Schooljobs</h1>
              <Badge
                variant="outline"
                className={`border-red-500 flex w-14 self-end justify-center`}
              >
                <div
                  style={{ width: "0.4rem", height: "0.4rem" }}
                  className="bg-red-500 rounded-full mr-1"
                ></div>
                <div className="font-semibold">LIVE</div>
              </Badge>
            </div>
          </NavLink>

          <div className="ml-8 hidden md:inline-block">
            <Button className="text-base" variant={"ghost"}>
              <MagnifyingGlassIcon className="mr-2 h-4 w-4" />{" "}
              <NavLink to={"./search/jobs"}>
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "border-b-lime-500 border-b-4 p-2" : "p-2"
                    }
                  >
                    Search Jobs
                  </span>
                )}
              </NavLink>
            </Button>
            <Button className="text-base" variant={"ghost"}>
              <CardStackPlusIcon className="mr-2 h-4 w-4" />{" "}
              <NavLink to={"./recruiter/post-job"}>
                {({ isActive }) => (
                  <span
                    className={
                      isActive ? "border-b-lime-500 border-b-4 p-2" : "p-2"
                    }
                  >
                    Post a job
                  </span>
                )}
              </NavLink>
            </Button>
          </div>
        </div>

        <div className="min-w-[40px] flex items-center">
          <SignedIn>
            <ClientOnly fallback={<Spinner />}>
              {() => (
                <div className="mr-3">
                  <Novu />
                </div>
              )}
            </ClientOnly>
            <MobileMenu />
          </SignedIn>
          <SignedOut>
            <NavLink to={"/sign-in"}>
              <Button variant={"link"}>Sign in</Button>
            </NavLink>
          </SignedOut>
        </div>
      </header>
    </>
  );
}
