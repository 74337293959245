import { Button } from "./ui/button";
import { Cross2Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetTrigger,
} from "./ui/sheet";
import { UserButton, useAuth, useUser } from "@clerk/remix";
import { Link, useNavigate } from "@remix-run/react";
import RecruiterMenu from "./recruiterMenu";
import { create } from "zustand";
import { TypographyH2 } from "./ui/typography/h2";
import CandidateMenu from "./candidateMenu";
import { useCurrentUserType } from "~/root";

export const useMobileMenuOpen = create<{
  isOpen: boolean;
  open: () => void;
  close: () => void;
}>((set) => ({
  isOpen: false,
  open: () => set(() => ({ isOpen: true })),
  close: () => set(() => ({ isOpen: false })),
}));

export function MobileMenu({ className }: { className?: string }) {
  const { isSignedIn, user } = useUser();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const isMenuOpen = useMobileMenuOpen((state) => state.isOpen);

  const closeMobileMenu = useMobileMenuOpen((state) => state.close);
  const openMobileMenu = useMobileMenuOpen((state) => state.open);

  const userType = useCurrentUserType((state) => state.userType);

  const isRecruiter = userType === "recruiter";
  const isCandidate = userType === "candidate";

  return (
    <div className={`${className}`}>
      <Sheet
        open={isMenuOpen}
        onOpenChange={(isOpen) => (isOpen ? openMobileMenu : closeMobileMenu)}
      >
        <SheetTrigger asChild>
          <Button variant="outline" onClick={openMobileMenu}>
            <HamburgerMenuIcon />
          </Button>
        </SheetTrigger>
        <SheetContent className="overflow-auto w-full mt-2 z-[100]">
          {isSignedIn ? (
            <div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <UserButton />
                  <TypographyH2 className="ml-4 text-lg">
                    Hello, {user?.fullName}
                  </TypographyH2>
                </div>
                <div className="flex">
                  <SheetClose onClick={closeMobileMenu}>
                    <Cross2Icon className="h-6 w-6" />
                    <span className="sr-only">Close</span>
                  </SheetClose>
                </div>
              </div>
              {isRecruiter ? (
                <div className="space-y-4 mt-8">
                  <RecruiterMenu isMobile={true} />
                </div>
              ) : null}

              {isCandidate ? (
                <div className="space-y-4 mt-8">
                  <CandidateMenu isMobile={true} />
                </div>
              ) : null}

              {!isRecruiter && !isCandidate ? (
                <div>
                  <ul className=" space-y-4 mt-8">
                    <Link to={"./search/jobs"}>
                      <span>Search jobs</span>
                    </Link>
                    <li>
                      <Link to={"./candidate/cv"}>
                        <span>Post your CV</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"./recruiter/post-job"}>
                        <span>Post a job</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <div className="flex w-full justify-end">
                <SheetClose className="flex" onClick={closeMobileMenu}>
                  <Cross2Icon className="h-6 w-6" />
                  <span className="sr-only">Close</span>
                </SheetClose>
              </div>
              <ul className=" space-y-4 mt-8">
                <Link to={"./search/jobs"}>
                  <span>Search jobs</span>
                </Link>
                <li>
                  <Link to={"./candidate/cv"}>
                    <span>Post your CV</span>
                  </Link>
                </li>
                <li>
                  <Link to={"./recruiter/post-job"}>
                    <span>Post a job</span>
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <SheetFooter>
            <SheetClose asChild onClick={closeMobileMenu}>
              {isSignedIn ? (
                <Button
                  className="text-white text-lg flex-1 bg-lime-600 hover:bg-lime-800 h-8 w-full mt-8 self-center"
                  variant="secondary"
                  onClick={() => {
                    signOut(() => {
                      navigate(".");
                    });
                    closeMobileMenu();
                  }}
                >
                  Sign out
                </Button>
              ) : (
                <Button
                  className="text-white text-lg flex-1 bg-lime-600 hover:bg-lime-800 h-8 w-full mt-8 self-center"
                  variant="secondary"
                  asChild
                >
                  <Link to="./sign-in">Sign in</Link>
                </Button>
              )}
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
}
