import type { LinkProps } from "@remix-run/react";
import { NavLink } from "@remix-run/react";
import { useMobileMenuOpen } from "./mobileMenu";

export default function SideMenuItem({
  to,
  name,
  icon,
}: {
  to: LinkProps["to"];
  name: string;
  icon: any;
}) {
  const closeMobileMenu = useMobileMenuOpen((state) => state.close);

  return (
    <li className="flex h-8 items-center m-6 ml-0">
      <NavLink
        to={to}
        className="m-2 ml-4"
        onClick={() => {
          closeMobileMenu();
        }}
      >
        {({ isActive, isPending }) => (
          <div className="flex">
            <div className={`${isActive ? "text-lime-700" : ""}`}>{icon}</div>
            <p
              className={`text-lg ml-4 ${
                isActive
                  ? "text-lime-700 border-b-lime-500 border-b-2 font-medium"
                  : ""
              }`}
            >
              {name}
            </p>
          </div>
        )}
      </NavLink>
    </li>
  );
}
